$colors: (
  'gray100': $gray100,
  'gray200': $gray200,
  'gray300': $gray300,
  'gray400': $gray400,
  'gray500': $gray500,
  'gray600': $gray600,
  'gray700': $gray700,
  'gray800': $gray800,
  'gray900': $gray900,
  'primary': $primary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'selected': $selected,
  'light': $light,
  'dark': $dark,
  'white': $white,
  'black': $black
);

// перебираем перечисление
@each $name, $color in $colors {
  // цвет текста
  .color-#{$name} {
    color: $color;
  }

  // цвет фона
  .bg-#{$name} {
    background-color: $color;
  }

  // заливка `svg`
  .fill-#{$name} {
    svg {
      color: $color;
    }
  }
}