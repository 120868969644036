@each $v in 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12 {
    .w-#{$v} {
      width: calc(100% / 12 * $v);
    }
  
    .h-#{$v} {
      height: calc(100% / 12 * $v);
    }
  }
  
  // дополнительно
  .w-full {
    width: 100vw;
  }
  
  .h-full {
    height: 100vh;
  }