.App {
  font-family: "Roboto", "Open Sans", Arial, Helvetica, sans-serif;
}

.content {
  padding: 0rem;
  width: 100%;
  background-color: white;
}

.wide-spin {
  width: 100%;
  text-align: center;
  margin-top: 200px;
}

.fogfree-logo {
  margin-top: 20px;
  background-repeat: no-repeat;
  background-position: center;
  height: 38px;
  background-image: url(./images/bulb.svg);
}